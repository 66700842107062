import React from "react";
import './Body.css'
import profile from "../Images/ProfilePhoto.png";
import html from "../Images/HTML.png";
import css from "../Images/CSS.png";
import javascript from "../Images/JavaScript.png";
import bootstrap from "../Images/BootStrap.png";
import react from "../Images/React.png";
import redux from "../Images/Redux.png";
import Tailwind from '../Images/Tailwind.png'
import { FcManager } from "react-icons/fc";
import { MdLocationOn } from "react-icons/md";
import { AiFillLinkedin, AiFillGithub } from "react-icons/ai";
import Project from "../Projects/Project";
import Contact from "../Contact/Contact";


const Body = () => {
  return (
    <div>
      <div className="sm:flex  justify-around my-16 sm:mx-[10%] items-center">
        <div>
          <h1 className="font-bold sm:text-7xl text-5xl  sm:text-left text-center">
            Front-End <span className="text-blue-700"> React</span>, <br />
            Developer  <span className="sm:block hidden"> <FcManager /></span>
          </h1>
          <p className="sm:text-2xl text-base sm:mx-0 mx-4 mt-6 font-semibold flex">
            Hi, I'm Ankit Payal. A passionate Front-end React Developer based in <br/>
            Hisar, Haryana.
            <span className="text-3xl sm:block hidden">
              <  MdLocationOn />
            </span>
          </p>

          <h3 className="flex text-3xl sm:mt-8 mt-4 ">
            <a className="mx-8"
              href="https://www.linkedin.com/in/ankit-payal1405/"
              rel="noreferrer"
              target="_blank"
            >
              <AiFillLinkedin />
            </a>
            <a
              href="https://github.com/AnkitPayal1405/"
              rel="noreferrer"
              target="_blank"
            >
              <AiFillGithub />
            </a>
          </h3>
        </div>

        <div >
          <img className="imagine" src={profile} alt="profile pic" />
        </div>
      </div>
      <div className="sm:flex  items-center justify-center">
        <div>
        <h4 className="font-bold sm:text-left text-center text-3xl ml-12">Tech Stack</h4>
        </div>
          <marquee direction="right" loop="30">
        <div className="flex flex-wrap">
        <img className="sm:h-16 h-9 mx-6 my-3 " src={html} alt="HTML" />
        <img className="sm:h-16 h-9 mx-6 my-3" src={css} alt="CSS" />
        <img className="sm:h-16 h-9 mx-6 my-3" src={javascript} alt="JavaScript" />
        <img className="sm:h-16 h-9 mx-6 my-3" src={bootstrap} alt="BootStrap" />
        <img className="sm:h-16 h-9 mx-6 my-3" src={Tailwind} alt="Tailwind" />
        <img className="sm:h-16 h-9 mx-6 my-3" src={react} alt="React" />
        <img className="sm:h-16 h-9 mx-6 my-3" src={redux} alt="React" /> 
        </div></marquee>
      </div>
      <Project/>
      <Contact/>
    </div>
  );
};

export default Body;
