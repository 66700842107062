import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import { AiOutlineMenu , AiOutlineClose } from "react-icons/ai";
import ankitpayalCV from "../Images/ANKITPAYAL_CV.pdf"

const Header = () => {
  const [menuHide, setMenuHide] = useState(false)
 
  return (
    <div>
    <div className='flex justify-between  items-center sticky p-6 w-full sm:w-auto shadow-2xl'>
        <div className='flex'>
           <Link to="/" ><h2 className='font-bold  sm:text-3xl text-xl text-blue-600'> AnkitPayal.com </h2></Link>
      </div>
        
        <div onClick={()=> setMenuHide(!menuHide)} className='text-3xl absolute right-8   cursor-pointer sm:hidden'>
      {menuHide ? <AiOutlineClose/> :<AiOutlineMenu/>}
    </div>
        <div className=''>
            <ul className={`sm:flex sm:items-center sm:pb-0 pb-12 absolute sm:static bg-white sm:z-auto z-[-1] right-0 w-full sm:w-auto sm:pl-0 pl-60 transition-all duration-500 ease-in ${menuHide ? "top-20" :"top-[-490px]"}`}>
               <Link to="/" > <li className='sm:ml-8 sm:my-0 my-7 font-bold' onClick={()=>setMenuHide(false)}>Home</li> </Link>
               <Link to="/project" > <li className='sm:ml-8 sm:my-0 my-7 font-bold' onClick={()=>setMenuHide(false)}>Project</li> </Link>
               <a  className='sm:ml-8 sm:my-0 my-7 font-bold' onClick={()=>setMenuHide(false)} href={ankitpayalCV} rel="noreferrer"  target="_blank" > <li>Resume</li> </a>
               <Link to="/contact" > <li className='sm:ml-8 sm:my-0 my-7 font-bold' onClick={()=>setMenuHide(false)}>Contact</li> </Link>
              
            </ul>
            
        </div>
        </div>


        
    </div>
  )
}

export default Header