import React from 'react'
import khana from '../Images/khana.mp4'
import Youtube from '../Images/Youtube.mp4'
import Netflix from '../Images/Netflix.mp4'
import cashRegister from '../Images/cashRegister.mp4'
import { FcPositiveDynamic } from "react-icons/fc";
import { AiFillGithub, AiOutlineExport } from "react-icons/ai";
import { Link } from 'react-router-dom';

const Project = () => {
  return (
    <div className='my-16'>
        <h1 className='font-bold my-8 sm:text-4xl text-2xl sm:justify-center sm:text-center text-center sm:flex ' >Each project is a unique piece of development.<span className='sm:block hidden'><FcPositiveDynamic/></span> </h1>



        <div className='mt-8 flex sm:flex-row flex-col shadow-xl rounded-2xl border-2 border-gray-200  sm:mx-[20%] mx-[10%] p-4' >
            <div>
            <video loop muted autoPlay="autoPlay"  className='sm:h-72 h-40 sm:w[60%] w-[85%] rounded-2xl '>
            <source src={Netflix} type='video/mp4'/>
          </video>
            </div>
            <div className='sm:mx-8 mx-4 sm:w-[40%] w-full'>
                <h2 className='font-bold sm:text-3xl text-xl'>Netflix-GPT</h2>
                <div className='text-sm sm:text-sm md:text-base lg:text-base '>
                    <li>Used Firebase for authentication</li>
                    <li>Integrated TMDB API for movie data</li>
                    <li>Multi-language support</li>
                    <li>Leverage GPT for efficient content discovery</li>
                    <li>Fully Responsive</li>
                </div>
                <div>
                <button className='bg-gray-200 px-3 py-1 rounded-lg font-bold mr-2 m-2'>React</button> <button  className='bg-gray-200 px-3 py-1 rounded-lg font-bold mr-2 m-2'>Redux</button> <button  className='bg-gray-200 px-3 py-1 rounded-lg font-bold mr-2 m-2'>Firebase</button>
                </div>
                
               <div className='flex my-4' > 
              <Link to="https://github.com/AnkitPayal1405/Netflix-GPT" target="_blank"> <h3 className='flex mr-4 items-center sm:text-xl text-base hover:text-blue-700'>Code<AiFillGithub/> </h3> </Link> 
               <Link to="https://netflix-gpt-by-ankitpayal.netlify.app/" target="_blank"> <h3 className='flex ml-4 items-center sm:text-xl text-base hover:text-blue-700'> Live Demo<AiOutlineExport/></h3></Link>
               </div>
            </div>
        </div>


        <div className=' mt-8 flex sm:flex-row-reverse flex-col shadow-xl rounded-2xl border-2 border-gray-200  sm:mx-[20%] mx-[10%] p-4' >
            <div>
            <video loop muted autoPlay="autoPlay"  className='sm:h-72 h-40 sm:w[60%] w-[85%] rounded-2xl '>
            <source src={Youtube} type='video/mp4'/>
          </video>
            </div>
            <div className='sm:mx-8 mx-4 sm:w-[40%] w-full'>
                <h2 className='font-bold sm:text-3xl text-xl'>Youtube Clone</h2>
                <div className='text-sm sm:text-sm md:text-base lg:text-base '>
                <li>Used Youtube Free API</li>
                    <li>Used Redux for State Management</li>
                    <li>Build Debouncing technic in Searching</li>
                    <li>Build a Live Chat Feature</li>
                    <li>Fully Responsive</li>  
                </div>
                <div>
                <button className='bg-gray-200 px-3 py-1 rounded-lg font-bold mr-2 m-2'>React</button> <button  className='bg-gray-200 px-3 py-1 rounded-lg font-bold mr-2 m-2'>Redux</button> <button  className='bg-gray-200 px-3 py-1 rounded-lg font-bold mr-2 m-2'>Tailwind CSS</button>
                </div>
               <div className='flex my-4' > 
              <Link to="https://github.com/AnkitPayal1405/youtube-clone" target="_blank"> <h3 className='flex mr-4 items-center sm:text-xl text-base hover:text-blue-700'>Code<AiFillGithub/> </h3> </Link> 
               <Link to="https://youtube-clone-by-ankitpayal.netlify.app/" target="_blank"> <h3 className='flex ml-4 items-center sm:text-xl text-base hover:text-blue-700'> Live Demo<AiOutlineExport/></h3></Link>
               </div>
            </div>
        </div>


        <div className='mt-8 flex sm:flex-row flex-col shadow-xl rounded-2xl border-2 border-gray-200  sm:mx-[20%] mx-[10%] p-4' >
            <div>
            <video loop muted autoPlay="autoPlay"  className='sm:h-72 h-40 sm:w[60%] w-[85%] rounded-2xl '>
            <source src={khana} type='video/mp4'/>
          </video>
            </div>
            <div className='sm:mx-8 mx-4 sm:w-[40%] w-full'>
                <h2 className='font-bold sm:text-3xl text-xl'>Food Ordering App</h2>
                <div className='text-sm sm:text-sm md:text-base lg:text-base '>
                    <li>Used Swiggy's Live API</li>
                    <li>Used Redux for State Management</li>
                    <li>Build Search Functionality</li>
                    <li>Made Shimmer UI as lazy loading</li>
                    <li>Fully Responsive</li>
                </div>
                <div>
                <button className='bg-gray-200 px-3 py-1 rounded-lg font-bold mr-2 m-2'>React</button> <button  className='bg-gray-200 px-3 py-1 rounded-lg font-bold mr-2 m-2'>Redux</button> <button  className='bg-gray-200 px-3 py-1 rounded-lg font-bold mr-2 m-2'>Tailwind CSS</button>
                </div>
                
               <div className='flex my-4' > 
              <Link to="https://github.com/AnkitPayal1405/food-ordering" target="_blank"> <h3 className='flex mr-4 items-center sm:text-xl text-base hover:text-blue-700'>Code<AiFillGithub/> </h3> </Link> 
               <Link to="https://food-ordering-by-ankitpayal.netlify.app/" target="_blank"> <h3 className='flex ml-4 items-center sm:text-xl text-base hover:text-blue-700'> Live Demo<AiOutlineExport/></h3></Link>
               </div>
            </div>
        </div>






        <div className=' mt-8 flex sm:flex-row-reverse flex-col shadow-xl rounded-2xl border-2 border-gray-200  sm:mx-[20%] mx-[10%] p-4' >
            <div>
            <video loop muted autoPlay="autoPlay"  className='sm:h-72 h-40 sm:w[60%] w-[85%] rounded-2xl '>
            <source src={cashRegister} type='video/mp4'/>
          </video>
            </div>
            <div className='sm:mx-8 mx-4 sm:w-[40%] w-full'>
                <h2 className='font-bold sm:text-3xl text-xl'>Cash Register App</h2>
                <div className='text-sm sm:text-sm md:text-base lg:text-base my-8 '>
                    <li>Used HTML CSS Javascript</li>
                    <li>Fully Responsive</li> 
                </div>
                <div>
                <button className='bg-gray-200 px-3 py-1 rounded-lg font-bold mr-2 m-2'>React</button> <button  className='bg-gray-200 px-3 py-1 rounded-lg font-bold mr-2 m-2'>Redux</button> <button  className='bg-gray-200 px-3 py-1 rounded-lg font-bold mr-2 m-2'>Tailwind CSS</button>
                </div>
               <div className='flex my-4' > 
              <Link to="https://github.com/AnkitPayal1405/cashcounter" target="_blank"> <h3 className='flex mr-4 items-center sm:text-xl text-base hover:text-blue-700'>Code<AiFillGithub/> </h3> </Link> 
               <Link to="https://cash-registering-app-by-ankit.netlify.app/" target="_blank"> <h3 className='flex ml-4 items-center sm:text-xl text-base hover:text-blue-700'> Live Demo<AiOutlineExport/></h3></Link>
               </div>
            </div>
        </div>


      

        
    </div>
  )
}

export default Project