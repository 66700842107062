import React from 'react'
import { ImMail3, ImLinkedin, ImLocation2 } from "react-icons/im";
import { FaHandPointDown } from "react-icons/fa";
const Contact = () => {
  return (
    <div className='my-16'>
        <h1 className='font-bold text-5xl text-center text-blue-600'>Contact</h1>
        <h3 className='flex justify-center items-center mt-4 text-xl'>Don't be Shy hit me up, <span className=' text-3xl'> <FaHandPointDown/> </span></h3>
        
        <div className='my-16'>
        <div className='flex  items-center my-8 border-2 sm:mx-[35%] mx-[2%] py-3 sm:px-8 px-0 border-gray-200 rounded-2xl shadow-xl'>
                <div className='text-3xl mx-4 rounded-full p-2 border-2 border-gray-200 shadow-xl text-blue-600'>
                    <ImLocation2/>
                </div>
                <div className='sm:ml-[10%] ml-0'> 
                <h4 className='sm:text-xl text-base font-semibold'>Location</h4>
                <p className='sm:text:base text-sm hover:text-blue-600'>Hisar, Haryana</p>
                </div>
        </div>

        <div className='flex  items-center my-8 border-2 sm:mx-[35%] mx-[2%] py-3 sm:px-8 px-0 border-gray-200 rounded-2xl shadow-xl'>
         <div className='text-3xl mx-4 rounded-full p-2 border-2 border-gray-200 shadow-xl text-blue-600'>
                    <ImMail3/>
                </div>
                <div className='sm:ml-[10%] ml-0'> 
                <h4 className='sm:text-xl text-base font-semibold'>Mail</h4>
               <a  href="mailto:ankitpayal21@gmail.com" rel="noreferrer" target="_blank"> <p className='sm:text:base text-sm hover:text-blue-600'>ankitpayal21@gmail.com</p> </a>
                </div>
        </div>

        <div className='flex  items-center my-8 border-2 sm:mx-[35%] md:-[10%] mx-[2%] py-3 sm:px-8 px-0 border-gray-200 rounded-2xl shadow-xl'>
                <div className='text-3xl mx-4 rounded-full p-2 border-2 border-gray-200 shadow-xl text-blue-600'>
                <ImLinkedin/>
                </div>
                <div className='sm:ml-[10%] ml-0'> 
                <h4 className='sm:text-xl text-base font-semibold'>LinkedIn</h4>
               <a href='https://www.linkedin.com/in/ankit-payal-dev/' rel="noreferrer" target="_blank" ><p className='sm:text:base text-sm hover:text-blue-600'>/ankit-payal-dev</p></a> 
                </div>
        </div>
        </div>

        <div>
          
        </div>
    </div>
  )
}

export default Contact