import React from 'react'
import { AiFillLinkedin, AiFillGithub, AiFillInstagram } from "react-icons/ai";

const Footer = () => {
  return (
    <div className='bg-black text-white p-2 mt-[8%]'>
        <h4 className='sm:text-xl text-base text-center'>Copyright © 2023. All rights are reserved</h4>
        <h1 className='flex justify-center  text-2xl '>
            <a className='sm:mx-4 mx-2 my-2' href='https://www.linkedin.com/in/ankit-payal1405/' rel="noreferrer" target="_blank" ><AiFillLinkedin/> </a>
           <a className='sm:mx-4 mx-2 my-2' href='https://github.com/AnkitPayal1405/' rel="noreferrer" target="_blank" > <AiFillGithub/> </a>
           <a className='sm:mx-4 mx-2 my-2' href='https://www.instagram.com/ankit_payal21' rel="noreferrer" target="_blank" > <AiFillInstagram/> </a>
            </h1>
    </div>
  )
}

export default Footer