
import Body from './Body/Body';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import Project from './Projects/Project';
import { createBrowserRouter, Outlet } from 'react-router-dom';

function App() {

  return (
    <div className="App">
    <header> <Header /> </header>
    <Outlet/>
    <footer> <Footer/> </footer>
    </div>
  );
}

export const appRouter =  createBrowserRouter([
  {
    path:'/',
    element:<App/>,
    children:[
      {
        path:'/',
        element:<Body/>
      },
      {
        path:'/contact',
        element:<Contact/>
      },{
        path:'/project',
        element:<Project/>
      }
    ]
  }
])

export default App;
